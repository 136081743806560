import axios from "axios";
import { useToken } from "./user/getUserToken";

const TEST_URL = 'https://prestable.beautyscan.ru'
const PROD_URL = 'https://refactor.api.beautyscan.ru'

const BASE_URL = `${TEST_URL}`;

const handleRequest = async (requestFunc) => {
  try {
    const response = await requestFunc();
    return response.data;
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    if (error.response) {
      console.error('Код состояния:', error.response.status);
      console.error(error.response.data.error)

      if (error.response.status === 500) {
        showErrorOverlay(500, 'Сервер недоступен');
        window.scrollTo(0, 0);
      } else if (error.response.status === 404) {
        showErrorOverlay(404, 'Некорректный запрос');
        window.scrollTo(0, 0);
      }
    } else if (error.request) {
      console.error('Запрос был сделан, но ответа не получено:', error.request);
      showErrorOverlay('Нет ответа', 'Запрос был сделан, но ответа не получено');
      window.scrollTo(0, 0);
    } else {
      console.error('Произошла ошибка при настройке запроса:', error.message);
      showErrorOverlay('Ошибка', 'Произошла ошибка при настройке запроса');
      window.scrollTo(0, 0);
    }
    throw error;
  }
};

const showErrorOverlay = (errorCode, errorMessage) => {
  const overlay = document.createElement('div');
  overlay.id = 'error-overlay';
  overlay.className = 'error-overlay';

  const message = document.createElement('div');
  message.textContent = `Ошибка ${errorCode}: ${errorMessage}`;
  message.className = 'error-overlay-message';

  const retryButton = document.createElement('button');
  retryButton.textContent = 'Попробовать снова';
  retryButton.className = 'btn btn--pink';
  retryButton.addEventListener('click', hideErrorOverlay);

  const homeButton = document.createElement('button');
  homeButton.textContent = 'Вернуться на главную';
  homeButton.className = 'btn btn--grey';
  homeButton.addEventListener('click', () => {
    window.location.href = 'index.html';
  });

  overlay.appendChild(message);
  overlay.appendChild(retryButton);
  overlay.appendChild(homeButton);
  document.body.appendChild(overlay);
};

const hideErrorOverlay = () => {
  const overlay = document.getElementById('error-overlay');
  if (overlay) {
    document.body.removeChild(overlay);
  }
  location.reload();
};

const makeRequest = async (method, url, data = null) => {
  const token = useToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'J-Authorization': `${token}`,
    },
  };

  if (data) {
    config.body = JSON.stringify(data);
  }

  return handleRequest(() => axios({
    method: method,
    url: url,
    ...config,
    ...(data && { data: data })
  }));
};

const postData = (url, data) => makeRequest('post', url, data);
const getData = (url) => makeRequest('get', url);

function clearLocalStorageExcept(exceptions) {
  const savedItems = {};

  exceptions.forEach(key => {
    if (localStorage.getItem(key)) {
      savedItems[key] = localStorage.getItem(key);
    }
  });

  localStorage.clear();

  Object.keys(savedItems).forEach(key => {
    localStorage.setItem(key, savedItems[key]);
  });
}



export { postData, getData, BASE_URL, clearLocalStorageExcept };
